import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"

import FooterMail from "../components/FooterMail"

import "./case.scss"

// import IconArrowRightWhite from "../assets/icon-arrow-right-white.svg"
import SEO from "../mainComponents/seo"

import { useMediaQuery } from "../components/isMediaSmall"
import Wrapper from "../mainComponents/Wrapper"

export const query = graphql`
query CasesItemQuery($slug: String!) {
    casesDataJson(slug: {eq: $slug}) {
      id
      slug
      icon
      title
      hiddenTitle
      tech
      product {
        text
        view {
          id
          title
          link
          blue_arrow
        }
        overview
      }
      techList {
        id
        title
        text
        items {
          id
          icon
          title
        }
      }
      gallery {
        id
        title
        flexLayout
        images {
          id
          path
        }
        flexImages {
          id
          path
        }
      }
      next {
        title
        link
      }
      more {
        id
        title
        link
      }
    }
  }
  `

const CaseItem = ({ data }) => {
  const caseItem = data.casesDataJson

  const isMediaSmall = useMediaQuery("(max-width: 1140px)")

  const [caseTotalCount, setCaseTotalCount] = useState({})
  const [caseShowCount, setCaseShowCount] = useState({})

  const handleCaseCountTotal = (i, d) => {
    setCaseTotalCount(prevState => ({ ...prevState, [i]: d }))
    setCaseShowCount(prevState => ({ ...prevState, [i]: 4 }))
  }

  useEffect(() => {
    caseItem.gallery.map((elem, index) => {
      return(
        handleCaseCountTotal(index, elem.images.length)

      )
    })
  }, [caseItem.gallery])

  const handleShowMore = i => {
    setCaseShowCount(prevState => ({ ...prevState, [i]: prevState[i] + 4 }))
  }

  return (
    <Wrapper>
      <Layout>
        <SEO title={`${caseItem.title ?? caseItem.hiddenTitle}`} description={caseItem.tech} />

        <section className={`case ${caseItem.slug}`}>
          <div className="case-head">
            <img className="case-head__icon" src={caseItem.icon} alt={caseItem.title} />
            <h2 className="case-head__title">{caseItem.title}</h2>
            <h6 className="case-head__tech">{caseItem.tech}</h6>
          </div>

          <div className="case-product container">
            <div className="case-product__content">
              <h3 className="case-product__title">Product</h3>
              <p className="case-product__text">{caseItem.product.text}</p>
              {caseItem.product.view.map((elem, index) =>
                <a className="case-product__link" href={elem.link} key={index} target={elem.link ? "_blank" : "_self"}
                   rel="noopener noreferrer">
                  <span className="case-product__link_span">{elem.title}</span>
                  {console.log(elem)}
                  {!elem.blue_arrow ?
                  <svg className={'case-product__link_arrow'} width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill={"white"}/>
                  </svg> :
                  <svg className={'case-product__link_arrow'} width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="url(#paint0_linear)"/>
                    <defs>
                      <linearGradient id="paint0_linear" x1="0" y1="8.5" x2="47" y2="8.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#4481EB"/>
                        <stop offset="1" stop-color="#04BEFE"/>
                      </linearGradient>
                    </defs>
                  </svg>}
                </a>,
              )}
            </div>

            <div className="case-product__image">
              <img className="case-product__source" src={caseItem.product.overview} alt="" />
            </div>
          </div>

          <div className="container">
            <div className="case-techList">
              {caseItem.techList.map(elem =>
                <div className="case-techList__item" key={elem.id}>
                  <h3 className="case-techList__title">{elem.title}</h3>
                  {elem.text ? <p className="case-techList__text">{elem.text}</p> : null}

                  <h6 className="case-techList__tools">Tools we use:</h6>

                  <div className="case-techListTools">
                    {elem.items.map(tool =>
                      <div className="case-techListTools__item" key={tool.id}>
                        <img className="case-techListTools__icon" src={tool.icon} alt="" />
                        <h5 className="case-techListTools__title">{tool.title}</h5>
                      </div>,
                    )}
                  </div>
                </div>,
              )}
            </div>
          </div>

          <div className="case-gallery">
            <div className="case-gallery__wrapper container">
              {isMediaSmall ?
                caseItem.gallery.map((elem, elemIndex) =>
                  <div className={`case-gallery__block ${elem.flexLayout ? "flex-layout" : ""}`} key={elem.id}>
                    <h3 className="case-gallery__title">{elem.title}</h3>

                    <div className="case-galleryImage">
                      {elem.images.map((imageElem, imageElemIndex) => imageElemIndex < caseShowCount[elemIndex] ?
                        <div className="case-galleryImage__image" key={imageElem.id}>
                          <img className="case-galleryImage__source" src={imageElem.path} alt="" />
                        </div>
                        : null)}
                    </div>
                    {elem.flexLayout &&
                    <div className={"case-galleryImage case-flexImage"}>
                      {elem.flexImages.map(imageElem =>
                        <div className="case-galleryImage__image" key={imageElem.id}>
                          <img className="case-galleryImage__source" src={imageElem.path} alt="" />
                        </div>,
                      )}
                    </div>}

                    {caseTotalCount[elemIndex] > caseShowCount[elemIndex] ?
                      <button className="case-gallery__button" onClick={() => handleShowMore(elemIndex)}>more
                        screens</button> : null}
                  </div>)
                :
                caseItem.gallery.map(elem =>
                  <div className={`case-gallery__block ${elem.flexLayout ? "flex-layout" : ""}`} key={elem.id}>
                    <h3 className="case-gallery__title">{elem.title}</h3>

                    <div className="case-galleryImage">
                      {elem.images.map(imageElem =>
                        <div className="case-galleryImage__image" key={imageElem.id}>
                          <img className="case-galleryImage__source" src={imageElem.path} alt="" />
                        </div>,
                      )}
                    </div>
                    {elem.flexLayout &&
                    <div className={"case-galleryImage case-flexImage"}>
                      {elem.flexImages.map(imageElem =>
                        <div className="case-galleryImage__image" key={imageElem.id}>
                          <img className="case-galleryImage__source" src={imageElem.path} alt="" />
                        </div>,
                      )}
                    </div>}
                  </div>)
              }
            </div>
          </div>

          <div className="case-next">
            <h5 className="case-next__title">Next project</h5>

            <Link className="case-next__link" to={`/cases/${caseItem.next.link}/`}>{caseItem.next.title}</Link>
          </div>

          <div className="case-more">
            <div className="case-more__wrapper container">
              <h5 className="case-more__title">More projects</h5>

              <div className="case-more__block">
                {caseItem.more.map(elem =>
                  <Link className="case-more__link" key={elem.id} to={`/cases/${elem.link}/`}>{elem.title}</Link>,
                )}
              </div>
            </div>
          </div>
        </section>
      </Layout>

      <Footer>
        <FooterMail />
      </Footer>
    </Wrapper>
  )
}

export default CaseItem
